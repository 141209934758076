:root {
  --text-color: #333333;
  --color-accent: #EBEBEA;
  --color-accent-hover: #{darken(#EBEBEA, 5)};
}

html {
  height: 100vh;
  width: 100vw;
  background-color: var(--color-accent);
  font-size: 16px;
  color: var(--text-color);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px);
  height: calc(100vh - env(safe-area-inset-top, 0px) - env(safe-area-inset-bottom, 0px));
  width: calc(100vw - env(safe-area-inset-left, 0px) - env(safe-area-inset-right, 0px));
}

#root {
  height: 100%;
  width: 100%;
}

select,
textarea,
input {
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 1em;
  font-family: inherit;
  color: var(--text-color);
}

/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .1);

  &:hover {
    background: rgba(0, 0, 0, .2);
  }
}

button {
  border: 0;
  border-radius: .25rem;
  cursor: pointer;
  font-size: .65rem;
  text-transform: uppercase;
  font-weight: bold;
  padding: .3rem .75rem;
  min-height: 1.875rem;
  transition: .3s ease;
  flex-shrink: 0;
  background-color: var(--color-accent);
  display: inline-flex;
  justify-content: center;
  align-items: center;
 
  &:hover {
    background-color: var(--color-accent-hover);
  }

  &[disabled] {
    user-select: none;
    pointer-events: none;
    cursor: default;
  }
}

.button-round {
  border-radius: 20rem 20rem;
}

.button-danger {
  background-color: #c3423f;
  color: #fff;
  &:hover {
    background-color: darken(#c3423f, 5);
  }
}

.button-primary {
  background-color: #3A6EA5;
  color: #fff;

  &:hover {
    background-color: darken(#3A6EA5, 5);
  }
}

button.text-danger {
  &:hover {
    background-color: rgba(#c3423f, .1);
  }
}

button.text-primary {
  &:hover {
    background-color: rgba(#3A6EA5, .1);
  }
}

.text-primary {
  color: #3A6EA5;
}

.text-danger {
  color: #c3423f;
}

.release-notes {
  flex-grow: 1;
  overflow: auto;
  text-align: left;

  .header {
    background-color: rgba(255, 255, 255, .125);
    display: flex;
    justify-content: space-between;
    padding: .25rem 1rem;
    margin: 1rem 0;
    text-align: center;

    span {
      color: var(--color-accent);
    }
  }
}

.badge {
  background-color: #3A6EA5;
  color: #fff;
  border: 0;
  border-radius: .25rem;
  font-size: .65rem;
  text-transform: uppercase;
  font-weight: bold;
  padding: .25rem .5rem;
  flex-shrink: 0;
  white-space: nowrap;
}

@keyframes hide {
  to {
    top: -2.5rem;
  }
}

.offline {
  position: fixed;
  top: -2.5rem;
  right: .25rem;
  height: 2rem;
  padding: 0 1rem;
  color: #fff;
  background-color: #c3423f;
  border-radius: .25rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: .75rem;
  display: flex;
  align-items: center;
  transition: .3s ease;
  opacity: 0;
  z-index: 2500;

  &:before {
    content: '\f071';
    font-family: 'Font Awesome 5 Pro';
    margin-right: .5rem;
  }

  &.show {
    opacity: 1;
    top: .25rem;
  }

  &.online {
    background-color: #04724D;
    opacity: 1;
    top: .25rem;
    animation: hide .3s ease 1.5s forwards;

    &:before {
      content: '\f00c';
    }
  }
}

.tooltip {
  z-index: 100000 !important;
}

kbd {
  background-color: rgba(255, 255, 255, .25);
  padding: .125rem .25rem;
  border-radius: .125rem;
}
