.board {
  --card-width: 150px;
  --card-height: 150px;
  --card-ear-width: 10px;
  --card-title-lines: 1;
  --card-title-height: calc(var(--card-title-lines) * 20px);
  --slot-border-width: 3px;
  --slot-color: #888;
  --slot-hover-color: #333;
  --card-body-height: 5px;
  --card-selected-color: #333;
  --card-alignment: 'center';
  --slot-height: calc(var(--card-title-height) + var(--card-body-height) + 5px);
  --card-picker-size: 1.25rem;
  --menu-width: 200px;

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }

  &.hide-scrollbar .weeks {
    overflow: hidden;
  }

  &.loading {
    justify-content: center;
    align-items: center;

    i {
      font-size: 2rem;
    }
  }

  .latency {
    position: fixed;
    top: calc(env(safe-area-inset-bottom, 0px) + .25rem);
    right: calc(env(safe-area-inset-right, 0px) + 1rem);
    text-align: center;
    font-size: .5rem;
    z-index: 101;
    white-space: nowrap;
    padding: .125rem 0;
    color: #7CB518;
    font-weight: bold;

    &.slow {
      color: #C3423F;
    }
  }

  .app-version {
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom, 0px) + .25rem);
    right: calc(env(safe-area-inset-right, 0px) + .25rem);
    text-align: center;
    font-size: .5rem;
    z-index: 100;
    white-space: nowrap;
    padding: .125rem 0;
    border-radius: .5rem;
    cursor: pointer;
    width: 3.5rem;

    &:hover {
      background-color: var(--color-accent-hover);
    }
  }

  .show-menu,
  .go-home {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    top: calc(env(safe-area-inset-top, 0px) + .25rem);
    left: calc(env(safe-area-inset-left, 0px) + .25rem);
    position: absolute;
    transition: .15s;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 15%;
    padding: .25rem;
    z-index: 1020;

    &:hover {
      background-color: #fff;
    }
  }

  .go-home {
    z-index: 500;
  }

  .pincode {
    margin: auto;
    text-align: center;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      img {
        height: 150px;
        width: 150px;
      }
    }

    .title {
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75rem;

      a {
        margin-left: .5rem;
      }
    }

    .login {
      max-width: 100%;
      width: 300px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: .5rem;
      padding: .5rem;
      position: relative;
      margin: 1rem 0;

      input {
        width: calc(100% - 3rem);
        padding: 1rem;
        border-radius: 0;

        &:focus {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, .1);
        }
      }

      .submit {
        position: absolute;
        top: 50%;
        right: .5rem;
        transform: translateY(-50%);
        font-size: 1.25rem;
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    .error {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(110px + env(safe-area-inset-top, 0px)));
      font-size: 12px;
      letter-spacing: 1px;
      color: rgb(222, 74, 58);
      font-weight: bold;
    }
  }

  .backups {
    max-height: 200px;
    overflow: auto;
    padding-right: 15px;
    margin-right: -15px;
    margin-bottom: 20px;
  }

  .backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, .5);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 950;
  }

  .move-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: .125rem 0;
  }

  .result-description {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(2 * var(--card-ear-width) + var(--card-width));
    font-size: .75rem;
    white-space: nowrap;
    padding: .25rem;
    background-color: var(--color-accent);
    margin: .125rem calc(-1 * var(--card-ear-width));
    border-radius: .25rem;
    text-align: center;
    text-transform: uppercase;

    ~ .result-description {
      font-weight: bold;
      width: var(--card-width);
      margin: .125rem 0;
    }
  }

  .active-card {
    z-index: 1050;
    position: fixed;
    height: 0;
    width: 0;

    + .backdrop {
      z-index: 1040;
    }

    .slot-description {
      bottom: calc((var(--card-height) * .5) + .5rem);
      left: calc(var(--card-width) * -.5);
      position: absolute;
      display: flex;
      justify-content: center;
      width: var(--card-width);
      z-index: 1050;

      div {
        margin: 0 .25rem;
        padding: .375rem .5rem;
        border-radius: 5px;
        background-color: #fff;
        white-space: nowrap;
      }
    }

    &.expanded {
      top: 50%;
      left: 50%;
      --card-height: 400px;
      --card-width: 400px;
      --card-picker-size: 2rem;

      .card {
        top: 0 !important;
        left: 0 !important;
        transform: translate(-50%, -50%);

        .card-id {
          display: block;
        }

        .card-body {
          .card-actions {
            padding: 5px 10px;
            
            button {
              font-size: 1.25rem;
              width: 2rem;
              height: 2rem;
              padding: 0;
              margin: 0 1px;
            }
          }

          .card-picker {
            bottom: 43px;
          }
        }
      }
    }

    .card {
      .card-body {
        height: calc(var(--card-height) - var(--card-title-height));
        transition: unset;

        .card-actions {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: .25rem;
          border-top: 1px solid rgba(0, 0, 0, .25);

          button {
            font-size: .75rem;
            min-height: unset;
            width: 1.25rem;
            height: 1.25rem;
            padding: 0;
            color: rgba(0, 0, 0, .5);
            background-color: transparent;

            &.active,
            &:hover {
              background-color: rgba(0, 0, 0, .1);
            }

            &:hover {
              color: rgba(0, 0, 0, .75);
            }
          }
        }
        
        .card-picker {
          height: auto;
          bottom: 0;
          top: unset;
          flex-wrap: wrap;
          background-color: rgba($color: #000000, $alpha: .25);
          padding: .25rem;

          .card-type:hover {

            &:not(.active) {
              box-shadow: unset;
            }
            
            &:before {
              content: '\f00c';
            }
          }
        }
      }

      &.dark {
        .card-actions button {
          color: rgba(255, 255, 255, .5);

          &:hover {
            color: rgba(255, 255, 255, .75);
          }
        }
      }
    }
  }

  .flag {
    font-style: normal;
    z-index: 50;
    font-size: .5rem;
    font-weight: bold;
    font-family: 'Font Awesome 5 Pro';
    margin: 0 .125rem;
    border-radius: 50%;
    background-color: #fff;
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid;
    line-height: calc(1.25rem - 4px);
    text-align: center;

    &:before {
      content: '\f74c';
    }
  }

  .card {
    width: var(--card-width);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    color: var(--text-color);
    background-color: var(--card-bg-color);

    &.dark {
      color: #fff;
    }

    .card-id {
      display: none;
      position: absolute;
      z-index: 100;
      background: rgba(0, 0, 0, .25);
      border-radius: .25rem;
      padding: .125rem .25rem;
      top: .125rem;
      right: calc((var(--card-ear-width) * -1) + .125rem);
      font-size: .75rem;
    }

    &.selected {
      .card-body,
      .card-title {
        box-shadow: 0 0 0 3px var(--card-selected-color);
      }

      .card-body:before {
        content: '';
        height: 3px;
        top: var(--card-title-height);
        left: 0;
        width: 100%;
        display: block;
        background-color: inherit;
        z-index: 10;
        position: absolute;
        border-radius: 0;
      }
    }

    &.disabled {
      opacity: .5;
      cursor: default;
    }

    &.updated,
    &.updating {
      &:before {
        font-family: "Font Awesome 5 Pro";
        width: 1.25rem;
        height: 1.25rem;
        line-height: 1.25rem;
        text-align: center;
        font-size: 1rem;
        position: absolute;
        top: calc(var(--card-title-height) - 1rem);
        right: calc((var(--card-ear-width) * -1));
        z-index: 100;
        color: #7CB518;
        border-radius: 50%;
        background-color: #fff;
      }
    }

    &.updated {
      &:before {
        content: '\f560';
      }
    }

    &.updating {
      &:before {
        content: '\f00c';
      }
    }

    .card-title {
      padding: 0 2px;
      border-radius: 6px;
      margin-left: calc(var(--card-ear-width) * -1);
      width: calc(100% + var(--card-ear-width) * 2);
      height: var(--card-title-height);
      line-height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: inherit;
      z-index: 10;
      overflow: hidden;
      white-space: nowrap;

      input {
        background: transparent;
        border: 0;
        outline: 0;
        padding: 0;
        width: 100%;
        text-align: center;
        font-weight: inherit;
        cursor: pointer;
        color: inherit;
        text-align: var(--card-alignment);
      }

      > div {
        height: 1.25rem;
        width: 100%;
        display: flex;
        justify-content: var(--card-alignment);
        user-select: none;

        span {
          margin: 0 auto;

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .card-body {
      background: inherit;
      transition: height .15s ease;
      // margin-top: -3px;
      // height: $card-body-height + 3px;
      height: var(--card-body-height);
      overflow: hidden;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      text-align: var(--card-alignment);
      display: flex;
      flex-direction: column;

      p,
      textarea {
        background: transparent;
        border: 0;
        outline: 0;
        width: 100%;
        flex: 1 1 auto;
        resize: none;
        text-align: var(--card-alignment);
        cursor: pointer;
        overflow-x: hidden;
        color: inherit;
        margin: 0;
        padding: 0;
      }

      p {
        user-select: none;
      }
    }

    &.preview {
      z-index: 1000;
      position: fixed;
      opacity: .85;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      cursor: grabbing;

      .card-body {
        height: calc(var(--card-height) - var(--card-title-height) - var(--card-body-height));
        top: var(--card-title-height);
      }
    }
  }

  .slot-labels {
    display: flex;
    flex-direction: column;
    height: max-content;
    flex: 0 1 auto;
    padding: 0 5px;
    border-right: 2px dashed #bbb;

    &.with-headers {
      margin-top: 35px;
    }

    .slot-label {
      height: calc(var(--card-title-height) + var(--card-body-height) + 5px);
      margin-bottom: var(--slot-border-width);
      display: flex;
      align-items: center;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
      flex-shrink: 0;
      white-space: nowrap;
      min-width: 2.5rem;
    }
  }

  &.continuous {
    .slot-labels {
      margin-top: 40px;

      &.with-headers {
        margin-top: 40px + 25px;
      }
    }
  }

  .weeks {
    display: flex;
    overflow: auto;
    flex: 1;
    transition: .15s ease margin;

    .week {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      height: max-content;
      margin-bottom: 5px;

      +.slot-labels {
        border-left: 2px dashed #bbb;
      }

      .week-number {
        padding: 5px;
        font-weight: 900;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          width: 80px;
          margin: 0 5px;
          text-align: center;
        }

        button {
          background-color: transparent;
          
          &:hover {
            background-color: var(--color-accent-hover);
          }
        }
      }

      .days {
        display: flex;
        flex-grow: 1;
      }

      .day {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        height: max-content;
        width: calc(var(--card-width) + 4px + var(--card-ear-width) * 2);
        padding-bottom: .5rem;
        position: relative;

        &.weekend {
          background-color: #c8c8c7;

          .day-title {
            background-color: #c8c8c7;
          }
        }

        .day-title {
          text-transform: uppercase;
          font-size: 12px;
          text-align: center;
          color: #555;
          white-space: nowrap;
          overflow: hidden;
          line-height: 25px;
          width: 100%;
          position: relative;

          &.today {
            font-weight: bolder;
            color: var(--text-color);
          }
        }

        .slot {
          height: calc(var(--slot-height) + var(--slot-border-width));
          flex-shrink: 0;
          position: relative;
          transition: .15s ease;
          display: flex;
          align-items: flex-end;
          padding: 0 calc(var(--card-ear-width) + 2px);

          .slot-border {
            content: '';
            border: var(--slot-border-width) solid var(--slot-color);
            border-top-width: 0;
            position: absolute;
            height: calc(var(--card-body-height) + var(--slot-border-width));
            width: calc(var(--card-width) + (var(--slot-border-width) * 2));
            left: 50%;
            transform: translateX(-50%);
            bottom: var(--slot-border-width);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          &.over {
            .slot-border {
              border-color: var(--slot-hover-color);
            }
          }

          &:hover {
            background-color: rgba(0, 0, 0, .1);
            cursor: pointer;
          }

          &.has-card-picker {
            cursor: pointer;
          }

          &.multiple .card:not(.selected) {
            .card-title {
              box-shadow: 0 0 10px 2px rgba(0, 0, 0, .25);
            }

            .card-body {
              z-index: 10;
            }
          }

          > div:not(.card-picker) {
            flex: 1;
            width: 0;
          }

          .card {
            z-index: 1;
            width: 100%;
            margin-bottom: calc(var(--slot-border-width) * 2);

            &.dragging {
              display: none;
            }

            .card-body {
              p, textarea {
                overflow: hidden;
              }
            }
          }
        }
      }
    }

    &.sticky-headers {
      &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        height: calc(65px + env(safe-area-inset-top, 0px));
        width: 100%;
        background-color: var(--color-accent-hover);
        z-index: 2;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
      }

      .week-number {
        position: sticky;
        z-index: 10;
        top: 0;

        button {
          &:hover {
            background-color: var(--color-accent);
          }
        }
      }

      .day {
        .day-title {
          position: sticky;
          top: 40px;
          z-index: 10;
        }
      }
    }
  }

  &.fixed {
    .weeks {
      .day {
        .day-title {
          font-weight: bold;
          line-height: 35px;
          font-size: 16px;
        }
      }
  
      &.sticky-headers{
        &:before {
          height: 35px;
        }

        .day-title {
          top: 0;
        }
      }
    }
  }

  &.has-active-card {
    .weeks.sticky-headers {
      .day-title,
      &::before {
        z-index: 1010;
      }
    }

    .weeks .week .day .slot .card {
      z-index: 1005;
    }
  }

  .remove-card {
    width: calc((var(--card-width) + 4px + var(--card-ear-width) * 2) * var(--card-types));
    height: 100%;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: -2px;
    display: none;

    &.visible {
      display: block;
    }
  }

  .card-types {
    height: calc(10px + var(--card-height));
    background-color: var(--color-accent);
    width: 100%;
    position: relative;

    .card-type {
      position: absolute;
      bottom: 5px;
      left: calc(var(--card-ear-width) + 2px + (4px + var(--card-width) + var(--card-ear-width) * 2) * var(--card-type-index));

      &.card-holder {
        width: var(--card-width);
        height: auto;

        &.over {
          box-shadow: 0 0 5px 5px rgba(0, 0, 0, .25);
          border-radius: 5px;
        }
      }

      .card-body {
        height: calc(var(--card-height) - var(--card-title-height));        
      }

      .card-description {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        background-color: rgba(0, 0, 0, .5);
        color: #fff;
        border-radius: 20px 20px;
        text-transform: uppercase;
        font-size: .75rem;
        width: auto;
        padding: .125rem .5rem;
        white-space: nowrap;
        overflow: hidden;
        max-width: calc(100% - .25rem);
        height: 1.25rem;

        .text-fill {
          width: 100%;
        }
      }
    }
  }

  .card-count {
    position: absolute;
    bottom: 1.25rem;
    z-index: 110;
    background-color: rgba(222, 74, 58, .85);
    right: .125rem;
    color: #fff;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: .75rem;
    font-size: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25);
    cursor: pointer;

    &:hover {
      background-color: rgb(222, 74, 58);
    }
  }

  .card-picker {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: calc(var(--slot-border-width) * 2);
    width: 100%;
    z-index: 100;
    padding: 0 calc(var(--card-ear-width) + 2px);
    left: 0;

    a,
    .flag,
    .card-type {
      width: var(--card-picker-size);
      height: var(--card-picker-size);
      border-radius: .25rem;
      margin: 1px;
      cursor: pointer;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: calc(var(--card-picker-size) / 2);
    }

    .flag:hover,
    .flag.active,
    .card-type:hover,
    .card-type.active {
      box-shadow: 0 0 0 2px var(--card-selected-color);
      z-index: 1;
    }

    .card-type:hover {
      &:before {
        content: '\f067';
        font-family: 'Font Awesome 5 Pro';
      }
    }

    a {
      background-color: #aaa;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #999;
      }
    }

    .flag {
      border-radius: 50%;
    }
  }

  .board-buttons {
    display: flex;
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom, 0px) + 1.5rem);
    right: env(safe-area-inset-right, 0px);
    z-index: 150;

    button {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      text-align: center;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25);
      margin-right: .5rem;

      &:hover {
        background-color: #eee;
      }
    }
  }

  .settings {
    background-color: #fff;
    position: absolute;
    z-index: 1020;
    height: calc(100vh - 50px);
    width: calc(100vw - 50px);
    max-width: 800px;
    max-height: 800px;
    transform: translate(-50%, -50%);
    left: 50vw;
    top: 50vh;
    overflow: hidden;
    border-radius: 5px;

    .settings-header {
      background-color: var(--color-accent);
      padding: 10px 30px;
      margin: 0 -30px 20px;
      position: relative;

      button {
        position: absolute;
        right: 30px;
        top: 4px;
      }
    }

    .settings-title {
      background-color: var(--color-accent);
      padding: 10px 30px;
      display: flex;
      align-items: center;

      .settings-tabs {
        display: flex;
        align-items: center;
        margin-right: 50px;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        a {
          border-bottom: 2px solid transparent;
          cursor: pointer;
          padding: 5px 0;
          white-space: nowrap;

          + a {
            margin-left: 10px;
          }

          &:hover {
            border-bottom-color: var(--color-accent-hover);
          }

          &.active {
            border-bottom-color: #888;
          }
        }
      }

      .settings-close {
        font-size: 1.25rem;
        position: absolute;
        right: 30px;
        top: 10px;
      }
    }

    .settings-body {
      padding: 10px 30px;
      overflow: auto;
      height: calc(100% - 100px);
      flex-grow: 1;
    }

    .settings-footer {
      background-color: var(--color-accent);
      padding: 10px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .menu {
    height: 100%;
    background-color: var(--color-accent-hover);
    z-index: 100;
    position: absolute;
    width: var(--menu-width);
    left: calc(var(--menu-width) * -1);
    padding-top: calc(3rem + env(safe-area-inset-top));
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
    transition: .15s ease;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    overflow: auto;
    display: flex;
    flex-direction: column;

    .app-version {
      position: relative;
      width: 100%;
      margin-top: 1rem;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      height: 100%;

      li {

        &.separator {
          border-top: 1px solid rgba(0, 0, 0, .1);
        }

        button {
          // background-color: transparent;
          width: 100%;
          border-radius: 0;
          justify-content: left;
          display: flex;
          padding: 1rem;

          i {
            text-align: left;
            width: 1.25rem;
          }
        }

        &.menu-card-holder {
          position: relative;
          padding: .25rem;

          button {
            background-color: rgba(0, 0, 0, .25);
            color: #fff;
            border-radius: 20px 20px;
            text-transform: uppercase;
            font-size: .75rem;
            padding: .125rem .5rem;
          }

          .card-count {
            top: -.125rem;
          }

          .color {
            display: flex;
            width: .875rem;
            height: .875rem;
            margin-right: .375rem;
            border-radius: .25rem;
          }
        }
      }
    }
  }

  &.show-menu {
    // .weeks {
    //   margin-left: var(--menu-width);
    //   margin-right: calc(var(--menu-width) * -1);
    // }

    .menu {
      left: 0;
    }
  }

  &.app {

    .slot-labels.with-headers {
      margin-top: 2.5rem;
    }

    .weeks.sticky-headers {

      &:before {
        height: calc(2.5rem + env(safe-area-inset-top, 0px));
      }

      .day .day-title {
        top: 0;
      }
    }

    .week {
      flex: 1 1 auto;
      padding: 0 .5rem;
      margin-bottom: 0;

      .day {
        padding: 0 .5rem .5rem;
        width: calc(100% / var(--app-num-days));

        .prev-day,
        .next-day {
          position: absolute;
          top: .25rem;
          height: 2rem;
          width: 2rem;
          line-height: 2rem;
        }

        .prev-day {
          left: .25rem;
        }

        .next-day {
          right: .25rem;
        }

        .day-title {
          font-size: 1rem;
          line-height: 2.5rem;
          width: calc(100% + 1rem);
          margin-left: -.5rem;
        }

        .slot {
          .slot-border {
            width: calc(100% - (var(--slot-border-width) * 4) - 6px);
          }
        }
      }
    }

    .popup {
      height: calc(100% - 10px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
      max-height: calc(100% - 10px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
      bottom: unset;
      top: 50%;
      transform: translate(-50%, -50%);
      
      .cards > div {
        width: calc(((100vw - 2rem) / (var(--app-num-days) + 1)) - var(--card-ear-width));
        --card-height: calc(((100vw - 2rem) / (var(--app-num-days) + 1)) - var(--card-ear-width));

        .new-card,
        .card {
          width: 100%;
        }
      }
    }
  }
}

.file {
  display: flex;
  align-items: center;
  padding: 5px 0;
  
  .size {
    font-size: .75rem;
    font-weight: bold;
    margin-right: auto;
    margin-left: .5rem;
  }

  + .file {
    border-top: 1px solid #efefef;
  }
}

.connection {
  display: flex;
  align-items: center;
  padding: .5rem 0;

  + .connection {
    border-top: 1px solid #efefef;
  }

  .connection-idx {
    margin-right: 1rem;
    font-weight: bold;
    font-size: .75rem;
  }
  
  .connection-os {
    display: inline-block;
    margin-right: .5rem;
    font-weight: bold;
    background-color: #888;
    color: #fff;
    border-radius: .25rem;
    padding: .25rem .5rem;
    font-size: .75rem;
  }
  
  .connection-browser {
    display: inline-block;
  }
  
  .connection-ip {
    font-weight: bold;
    font-size: .75rem;
  }
}

.popup {
  background-color: #fff;
  left: 50%;
  bottom: calc(var(--card-height) + 25px);
  transform: translateX(-50%);
  max-width: calc(100vw - 10px);
  max-height: calc(100vh - var(--card-height) - 30px);
  border-radius: 5px;
  align-content: flex-start;
  z-index: 990;
  position: fixed;
  flex-direction: column;
  display: flex;
  overflow: hidden;

  &.full {
    height: calc(100vh - var(--card-height) - 30px);
    width: calc(100vw - 10px);
    
    .cards {
      width: 100%;
    }
  }

  .header {
    padding: .25rem;
    display: flex;
    background-color: var(--color-accent);
    z-index: 20;

    .flags {
      display: flex;
      align-items: center;

      .flag {
        cursor: pointer;
        
        &:hover,
        &.active {
          box-shadow: 0 0 0 2px #000;
        }
      }
    }

    .popup-expand {
      margin-left: auto;
    }

    .description {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;

      + .popup-close {
        margin-left: auto;
      }
      
      span {
        margin: 0 .125rem;
        padding: .375rem .5rem;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.1);
        white-space: nowrap;
      }
        
      .card-count {
        right: -2rem;
        bottom: .25rem;
      }
    }

    button {
      font-size: 1.25rem;

      &:hover {
        background-color: rgba(0, 0, 0, .1);
      }
    }
  }

  .body {
    overflow: auto;
    padding: 5px;
    min-height: calc(10px + 10px + 30px + .25rem + var(--card-height));
  }

  .cards {
    flex: 0 1 auto;
    display: flex;
    flex-wrap: wrap;
    width: calc(((var(--card-width) + 10px + (var(--card-ear-width) * 2)) * 4));

    > div {
      margin: 5px;
      padding: 0 var(--card-ear-width);
      position: relative;

      .card {
        position: relative;

        .card-body {
          height: calc(var(--card-height) - var(--card-title-height));
        }
      }
    }
  }

  .new-card {
    display: flex;
    flex-wrap: wrap;
    height: var(--card-height);
    width: var(--card-width);
    background-color: #e6e6e6;
    border-radius: 6px;
    justify-content: flex-start;
    align-content: flex-start;

    .card-type {
      width: calc(var(--card-width) / 3);
      height: calc(var(--card-width) / 3);
      position: relative;
      bottom: unset;
      left: unset;
      cursor: pointer;
      border-radius: .5rem;
      border: 2px solid #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border-color: #000;

        &:before {
          content: '\f067';
          font-family: 'Font Awesome 5 Pro';
          font-size: calc(var(--card-width) / 6);
        }
      }
    }
  }
}

.log {
  display: flex;
  padding: .5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  &:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  .log-id,
  .log-time {
    font-size: .75rem;
    padding: .125rem;
    background-color: rgba(0, 0, 0, .1);
    display: block;
    border-radius: .25rem;
    margin-bottom: .25rem;
  }

  .log-action {
    margin: 0 .25rem;
  }

  .log-id {
    margin-left: auto;
  }
}

.field {
  margin-bottom: 20px;
  display: flex;
  min-height: 30px;

  > label {
    font-weight: bold;
    width: 40%;
    padding-right: 15px;
    display: flex;
    padding: 6px 0;
    position: relative;

    .help {
      position: absolute;
      font-size: .75rem;
      bottom: -.5rem;
      font-weight: normal;
      font-style: italic;
    }
  }

  .input {
    width: 60%;
  }
    
  select,
  input {
    background-color: #eee;
    padding: 5px 10px;
    border-radius: 5px;
    -webkit-appearance: none;
    transition: .3s ease;
    width: 100%;

    &:focus {
      background-color: #ddd;
    }
  }

  input[type="color"] {
    padding: 0;
    background-color: transparent;
  }

  .checkboxes {
    display: flex;
    flex-direction: column;

    .checkbox {
      margin-bottom: 5px;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    
    label {
      display: flex;
      align-items: center;
    }

    input {
      display: none;
    }

    span {
      margin-right: 5px;
      font-family: 'Font Awesome 5 Pro';
      color: var(--text-color);
      background-color: #eee;
      border-radius: 5px;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      cursor: pointer;
      transition: .3s ease;

      &:hover {
        background-color: #ddd;
      }
    }

    input:checked + label span:before {
      content: '\f00c';
    }
  }
}

@media screen and (max-width: 768px) {
  .board {
    .settings {
      height: calc(100vh - 20px - env(safe-area-inset-top, 0px) - env(safe-area-inset-bottom, 0px));
      width: calc(100vw - 20px - env(safe-area-inset-left, 0px) - env(safe-area-inset-right, 0px));

      .settings-title {
        padding: 10px;

        .settings-close {
          right: 10px;
        }
      }

      .settings-header {
        padding: 10px;
        margin: 0 -10px 20px;
      }

      .settings-body {
        padding: 10px;
      }
    }
  }

  .field {
    flex-direction: column;

    label,
    .input {
      width: 100%;
    }

    label {
      flex-direction: column;

      .help {
        position: relative;
        display: block;
        bottom: unset;
      }
    }

    .checkbox {
      align-items: flex-start;
    }

    .checkbox label {
      flex-direction: row;
    }

    .checkboxes {
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: 420px) {
  .board .active-card.expanded {
    --card-width: 300px;
  }
}

@media screen and (max-height: 420px) {
  .board .active-card.expanded {
    --card-height: 300px;
  }
}

@media screen and (max-width: 320px) {
  .board .active-card.expanded {
    --card-width: 260px;
  }
}

@media screen and (max-height: 320px) {
  .board .active-card.expanded {
    --card-height: 260px;
  }
}
